<template>
    <v-dialog
        v-model="dialog"
        max-width="450"
        persistent
    >
        <v-card>
                <v-card-title
                    class="headline grey lighten-2"
                    primary-title
                >
                    {{nombre}}
                </v-card-title>
                <v-card-text class="pt-2">
                    <!-- ---------------------------------------------------- -->
                    <v-row class="text-center" no-gutters>
                        <v-col cols="12">
                            <v-slider v-model="valor" min="1" max="6"></v-slider>
                        </v-col>
                        <v-col cols="12" align="center">
                            <v-img
                                height="50"
                                width="300"
                                :src="escalaImagen"
                            />
                            {{escalaText}}: {{valor}}
                        </v-col>
                        <v-col cols="12" align="center">
                            <v-text-field
                                v-model="observaciones"
                                filled
                                rounded
                                clearable
                                dense
                                label="Observaciones"
                            ></v-text-field>
                        </v-col>

                    </v-row>

                    <!-- ---------------------------------------------------- -->
                </v-card-text>

                <v-card-actions>
                    
                    <v-btn
                        color="success"
                        :loading="loading"
                        @click="agrega"
                    >
                        Agregar
                    </v-btn>
                    
                    <v-btn color="error" @click="cancela">
                        Cancelar
                    </v-btn>
                </v-card-actions>
            </v-card>
    </v-dialog>
</template>

<script>
export default {
    props:{
        dialogDe:Boolean,
        turno:String,
        nombre:String,
        estaFecha: String,
        internamiento:Number,
    },

    data:()=>({
        loading:false,
        valor:0,
        observaciones:'',
    }),

    computed:{
        dialog:{
            get(){
                return this.dialogDe
            },
            set(val){
                this.$emit('update:dialogDe',val)
            },
        },
        escalaImagen(){
            let text = ''
            if (this.valor == 1)
                text='/images/escalas/ramsay-01.png'
            if (this.valor == 2)
                text='/images/escalas/ramsay-02.png'
            if (this.valor == 3)
                text='/images/escalas/ramsay-03.png'
            if (this.valor == 4)
                text='/images/escalas/ramsay-04.png'
            if (this.valor == 5)
                text='/images/escalas/ramsay-05.png'
            if (this.valor == 6)
                text='/images/escalas/ramsay-06.png'
            

            return text
        },
        escalaText(){
            let text = ''
            if (this.valor == 1)
                text='Despierto, ansioso y agitado'
            if (this.valor == 2)
                text='Despierto, cooperados, orientado y tranquilo'
            if (this.valor == 3)
                text='Dormido con respuesta a órdenes'
            if (this.valor == 4)
                text='Somnoliento con breves respuestas a la luz y al sonido'
            if (this.valor == 5)
                text='Dormido con respuesta sólo al dolor'
            if (this.valor == 6)
                text='Profundamente dormido, sin respuesta a estímulos'

            return text
        }
    },
    methods:{
        cancela(){
            this.observaciones = ''
            this.dialog = false
        },
         async agrega(){


             this.loading=true

            let datos = {
                fecha: this.estaFecha,
                turno: this.turno,
                escala: 'ramsay',
                valor: this.valor,
                internamiento:this.internamiento,
                observaciones: this.observaciones,
            }
            try{
                let dt = await this.$http({
                    url:'/enfermeria/guardaEscalas',
                    method: 'POST',
                    data: datos
                })
                if (dt.data.estado == true){
                    this.$emit('escalaSuccess')
                    this.observaciones = ''
                    this.valor = 0
                    this.loading = false
                    this.dialog = false
                } else {
                    console.log(dt.data)
                    this.loading = false
                    this.$swal.fire({
                        icon: 'warning',
                        title: 'No se puede guardar',
                        text: 'Verifique los datos e intente de nuevo',
                    })
                }
                
            } catch(err){
                console.log(err)
                this.$store.dispatch('security/revisaError',err)
                this.loading = false
            }

            // this.$emit('escalaSuccess')
            // this.observaciones = ''
            // console.log ('Actualizando DOLOR en en turno '+this.turno+' con los valores '+this.valor)
            // this.dialog = false
            
        },
    },
}
</script>

<style>

</style>